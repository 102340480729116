import React, { useContext} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import WaysToAccessIcon from "./waysToAccessIcon.js";
import { ThemeContext } from "../../context/ThemeContext";

import "./style.scss";

const WaysToAccess = () => {
  
  const theme = useContext(ThemeContext);

  const ACCESSMODES = [
    {
      icon: "Instagram",
      alt: "Instagram Icon",
    },
    {
      icon: "Facebook",
      alt: "Facebook Icon",
    },
    {
      icon: "Ticket",
      alt: "Ticket Icon",
    },
    {
      icon: "Google",
      alt: "Google Icon",
    },
    // {
    //   icon: "Camping",
    //   alt: "Camping Icon",
    // },
    {
      icon: "WhatsApp",
      alt: "WhatsApp Icon",
    },
    {
      icon: "Mail",
      alt: "Mail Icon",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="waysToAccessSlider">
          <Swiper
            spaceBetween={theme.isMobile ? 0 : -75}
            slidesPerView={theme.isMobile ? 2 : 5}
            slideToClickedSlide={true}
            centeredSlides={true}
            loop={true}
          >
            {ACCESSMODES.map((i, index) => (
              <SwiperSlide key={index}>
                {({ isActive }) =>
                  isActive ? (
                    <>
                      <div className="balloon">Accedi tramite {i.icon}</div>
                      <div className="slideButton">
                        <div className="slideIcon active">
                          <WaysToAccessIcon icon={i.icon} color="white" />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="slideButton">
                      <div className="slideIcon">
                        <WaysToAccessIcon icon={i.icon} />
                      </div>
                    </div>
                  )
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default WaysToAccess;

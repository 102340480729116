import React from "react";

const WaysToAccessIcon = ({ icon, color }) => {
  switch (icon) {
    case "WhatsApp":
      return (
        <svg
          width="35px"
          height="35px"
          viewBox="0 0 35 35"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="dashiconswhatsapp" transform="translate(1.0253906 1.0256348)">
            <path
              d="M29.6069 7.39977C24.8201 -0.000303228 15.047 -2.20032 7.46786 2.39972C0.0881837 6.99976 -2.30523 16.9999 2.48159 24.3999L2.88049 24.9999L1.28489 31L7.26841 29.4L7.86676 29.8C10.4596 31.2 13.2519 32 16.0442 32C19.036 32 22.0278 31.2 24.6206 29.6C32.0003 24.7999 34.1943 14.9998 29.6069 7.39977ZM22.2421 24.8451C21.4411 24.8451 20.4398 25.2392 16.4349 23.6628C13.0307 22.0865 10.2272 19.5249 8.22471 16.5692C7.02323 15.1899 6.42248 13.4165 6.22223 11.6431C6.22223 10.0667 6.82298 8.68742 7.82422 7.7022C8.22471 7.30811 8.62521 7.11107 9.02571 7.11107L10.0269 7.11107C10.4274 7.11107 10.8279 7.11107 11.0282 7.89924C11.4287 8.88447 13.0776 12.2784 12.809 12.982C12.6522 13.3925 11.7027 13.935 11.6289 14.0076C11.2284 14.2047 11.0282 14.5988 11.2284 14.9929C12.0294 16.1751 13.0307 17.3574 14.0319 18.3426C15.2334 19.3278 16.4349 20.116 17.8366 20.7071C18.2371 20.9042 18.6376 20.9042 18.8379 20.5101C19.0381 20.116 20.0393 19.1308 20.4398 18.7367C20.8403 18.3426 21.0406 18.3426 21.4411 18.5397L24.6451 20.116C25.0455 20.3131 25.446 20.5101 25.6463 20.7071C25.8465 21.2983 25.8465 22.0865 25.446 22.6776C24.6451 23.8599 23.6438 24.648 22.2421 24.8451Z"
              id="Shape"
              fill="none"
              fillRule="evenodd"
              stroke={color ? color : "#23b6ff"}
              strokeWidth="2.0512822"
            />
          </g>
        </svg>
      );
    case "Google":
      return (
        <svg
          width="30px"
          height="31px"
          viewBox="0 0 30 31"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group-8-Copy" transform="translate(1 1)">
            <g id="ant-designgoogle-outlined">
              <path
                d="M27.2138 11.4694L14.0125 11.4694L14.0125 16.8954L21.554 16.8954C21.2288 18.6492 20.2423 20.1327 18.7552 21.1265C17.4983 21.9669 15.8942 22.4638 14.0088 22.4638C10.3587 22.4638 7.27115 19.9975 6.16769 16.6835C5.89 15.8431 5.72923 14.9442 5.72923 14.0198C5.72923 13.0954 5.89 12.1965 6.16769 11.3562C7.27481 8.04577 10.3623 5.57942 14.0125 5.57942C16.0696 5.57942 17.9148 6.28827 19.369 7.67673L23.3883 3.65385C20.9585 1.38846 17.7906 0 14.0125 0C8.53539 0 3.79635 3.14231 1.49077 7.72423C0.540769 9.61692 0 11.7581 0 14.0235C0 16.2888 0.540769 18.4263 1.49077 20.319C3.79635 24.901 8.53539 28.0433 14.0125 28.0433C17.7979 28.0433 20.9694 26.7863 23.286 24.6452C25.935 22.2044 27.466 18.609 27.466 14.3377C27.466 13.3438 27.3783 12.3902 27.2138 11.4694Z"
                id="Path"
                fill="none"
                stroke={color ? color : "#23b6ff"}
                strokeWidth="2"
              />
            </g>
          </g>
        </svg>
      );
    case "Instagram":
      return (
        <svg
          width="32px"
          height="32px"
          viewBox="0 0 32 32"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="ant-designinstagram-filled" transform="translate(1 1)">
            <path
              d="M14.6019 9.73493C11.9199 9.73493 9.73128 11.9236 9.73128 14.6055C9.73128 17.2874 11.9199 19.4761 14.6019 19.4761C17.2838 19.4761 19.4724 17.2874 19.4724 14.6055C19.4724 11.9236 17.2838 9.73493 14.6019 9.73493ZM29.2099 14.6055C29.2099 12.5886 29.2282 10.5899 29.1149 8.57666C29.0017 6.2382 28.4682 4.16282 26.7582 2.45282C25.0445 0.739164 22.9728 0.209356 20.6344 0.0960868C18.6174 -0.0171824 16.6188 0.00108681 14.6055 0.00108681C12.5886 0.00108681 10.5899 -0.0171824 8.57666 0.0960868C6.2382 0.209356 4.16282 0.742818 2.45282 2.45282C0.739164 4.16647 0.209356 6.2382 0.0960868 8.57666C-0.0171824 10.5936 0.00108681 12.5922 0.00108681 14.6055C0.00108681 16.6188 -0.0171824 18.6211 0.0960868 20.6344C0.209356 22.9728 0.742818 25.0482 2.45282 26.7582C4.16647 28.4719 6.2382 29.0017 8.57666 29.1149C10.5936 29.2282 12.5922 29.2099 14.6055 29.2099C16.6224 29.2099 18.6211 29.2282 20.6344 29.1149C22.9728 29.0017 25.0482 28.4682 26.7582 26.7582C28.4719 25.0445 29.0017 22.9728 29.1149 20.6344C29.2319 18.6211 29.2099 16.6224 29.2099 14.6055ZM14.6019 22.0995C10.4547 22.0995 7.10782 18.7526 7.10782 14.6055C7.10782 10.4584 10.4547 7.11147 14.6019 7.11147C18.749 7.11147 22.0959 10.4584 22.0959 14.6055C22.0959 18.7526 18.749 22.0995 14.6019 22.0995ZM22.4028 8.55474C21.4345 8.55474 20.6526 7.77282 20.6526 6.80455C20.6526 5.83628 21.4345 5.05436 22.4028 5.05436C23.3711 5.05436 24.153 5.83628 24.153 6.80455C24.1536 7.26891 23.9694 7.71441 23.641 8.04276C23.3127 8.37112 22.8672 8.55532 22.4028 8.55474L22.4028 8.55474Z"
              id="Shape"
              fill="none"
              stroke={color ? color : "#23b6ff"}
              strokeWidth="2"
            />
          </g>
        </svg>
      );
    case "Facebook":
      return (
        <svg
          width="17px"
          height="31px"
          viewBox="0 0 17 31"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="bxbxl-facebook" transform="translate(1 1)">
            <path
              d="M9.49468 28.0615L9.49468 15.2822L13.8232 15.2822L14.4667 10.2786L9.49468 10.2786L9.49468 7.09159C9.49468 5.64776 9.89857 4.65921 11.9791 4.65921L14.6154 4.65921L14.6154 0.198285C13.3327 0.0613711 12.0434 -0.00473693 10.7533 0.000263768C6.92728 0.000263768 4.30039 2.32662 4.30039 6.59732L4.30039 10.2693L0 10.2693L0 15.2728L4.30979 15.2728L4.30979 28.0615L9.49468 28.0615Z"
              id="Path"
              fill="none"
              stroke={color ? color : "#23b6ff"}
              strokeWidth="2"
            />
          </g>
        </svg>
      );
    case "Ticket":
      return (
        <svg
          width="41px"
          height="41px"
          viewBox="0 0 41 41"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="heroicons-solidticket"
            transform="matrix(0.70710677 -0.70710677 0.70710677 0.70710677 1.4140625 22.627441)"
          >
            <g
              id="Group"
              transform="matrix(0.9999999 0 0 0.9999999 -0.00024414062 -0.0009765625)"
            >
              <path
                d="M0 3.74154C-4.15394e-16 1.67514 1.67514 0 3.74154 0L26.1908 0C28.2572 0 29.9323 1.67514 29.9323 3.74154L29.9323 7.48308C27.8659 7.48308 26.1908 9.15822 26.1908 11.2246C26.1908 13.291 27.8659 14.9662 29.9323 14.9662L29.9323 18.7077C29.9323 20.7741 28.2572 22.4492 26.1908 22.4492L3.74154 22.4492C1.67514 22.4492 0 20.7741 0 18.7077L0 14.9662C2.06639 14.9662 3.74154 13.291 3.74154 11.2246C3.74154 9.15822 2.06639 7.48308 0 7.48308L0 3.74154Z"
                id="Path"
                fill="none"
                stroke={color ? color : "#23b6ff"}
                strokeWidth="2"
              />
            </g>
          </g>
        </svg>
      );
    case "Camping":
      return (
        <svg
          width="34px"
          height="34px"
          viewBox="0 0 34 34"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="fa-solidcampground" transform="translate(1 1)">
            <path
              d="M30.78 27.6231L29.5626 27.6231L17.735 7.2604L20.3696 2.72542C20.6256 2.28456 20.5476 1.66736 20.1949 1.34735L18.9174 0.188789C18.5647 -0.131218 18.0714 -0.0337975 17.8149 0.407677L15.7846 3.90309L13.7538 0.40706C13.4978 -0.0337975 13.0041 -0.131834 12.6514 0.188173L11.3748 1.34735C11.0221 1.66736 10.9437 2.28456 11.2002 2.72542L13.8347 7.2604L2.00662 27.6231L0.789231 27.6231C0.353181 27.6231 0 28.0646 0 28.6096L0 30.5827C0 31.1278 0.353181 31.5692 0.789231 31.5692L30.78 31.5692C31.2161 31.5692 31.5692 31.1278 31.5692 30.5827L31.5692 28.6096C31.5692 28.0646 31.2161 27.6231 30.78 27.6231ZM15.7846 17.7577L21.5243 27.6231L10.0449 27.6231L15.7846 17.7577Z"
              id="Shape"
              fill="none"
              stroke={color ? color : "#23b6ff"}
              strokeWidth="2"
            />
          </g>
        </svg>
      );
    case "WiFi":
      return (
        <svg
          width="42px"
          height="30px"
          viewBox="0 0 42 30"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="fa-solidwifi" transform="translate(1 1)">
            <path
              d="M39.1476 7.57662C28.2236 -2.52739 11.2335 -2.52369 0.31379 7.57662C-0.0968566 7.95643 -0.104872 8.59953 0.29221 8.99353L2.4034 11.0881C2.78199 11.4642 3.39117 11.4722 3.78456 11.1115C12.7818 2.86898 26.6784 2.86713 35.6775 11.1115C36.0709 11.4722 36.6801 11.4636 37.0586 11.0881L39.1698 8.99353C39.5663 8.59953 39.5583 7.95643 39.1476 7.57662ZM19.7307 19.7308C17.5511 19.7308 15.7846 21.4973 15.7846 23.6769C15.7846 25.8566 17.5511 27.6231 19.7307 27.6231C21.9104 27.6231 23.6769 25.8566 23.6769 23.6769C23.6769 21.4973 21.9104 19.7308 19.7307 19.7308ZM32.2271 14.5767C25.1203 8.29186 14.3331 8.29864 7.23436 14.5767C6.80891 14.9528 6.79535 15.6058 7.19921 16.0041L9.32274 18.0999C9.69269 18.4649 10.2883 18.4896 10.6823 18.1492C15.8586 13.6747 23.6146 13.6845 28.7785 18.1492C29.1725 18.4896 29.7681 18.4655 30.1381 18.0999L32.2616 16.0041C32.6661 15.6058 32.6519 14.9522 32.2271 14.5767L32.2271 14.5767Z"
              id="Shape"
              fill="none"
              stroke={color ? color : "#23b6ff"}
              strokeWidth="2"
            />
          </g>
        </svg>
      );
    case "Mail":
      return (
        <svg
          width="33px"
          height="25px"
          viewBox="0 0 33 25"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.9846 22.8001L30.9846 2.8501L15.4923 15.6751L0 2.8501L0 22.8001L30.9846 22.8001ZM15.4921 11.4L29.576 0L1.4082 0L15.4921 11.4Z"
            transform="translate(1 1)"
            id="Shape"
            fill="none"
            fillRule="evenodd"
            stroke={color ? color : "#23b6ff"}
            strokeWidth="2"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="33px"
          height="25px"
          viewBox="0 0 33 25"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.9846 22.8001L30.9846 2.8501L15.4923 15.6751L0 2.8501L0 22.8001L30.9846 22.8001ZM15.4921 11.4L29.576 0L1.4082 0L15.4921 11.4Z"
            transform="translate(1 1)"
            id="Shape"
            fill="none"
            fillRule="evenodd"
            stroke={color ? color : "#23b6ff"}
            strokeWidth="2"
          />
        </svg>
      );
  }
};
export default WaysToAccessIcon;

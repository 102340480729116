import React from "react";
import Header from "../components/header/header.js";
import CategoryButtons from "../components/categoryButtons/categoryButtons";
import Auth from "../assets/img/svg/auth.svg";
import WaysToAccess from "../components/waysToAccess/waysToAccess.js";

const Authentication = () => {
  return (
    <>
      <Header
        title="Modalità di autenticazione"
        description={`Offre diverse modalità di autenticazione, alcune tra le più comuni, facili e fruibili da tutti. `}
        categoryIcon={{ icon: Auth, alt: "Modalità di autenticazione Banner" }}
        metaDescription="Offre diverse modalità di autenticazione, alcune tra le più comuni, facili e fruibili da tutti."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              Un potente tool di customizzazione rende il tuo accesso unico.
            </h2>
            <p className="description">
              <br />
              <br />
              Per ogni modalità di autenticazione è previsto un diverso
              redirect, che ti consentirà di comunicare con i follower della
              piattaforma social scelta, così potrai promuovere la tua attività
              e far crescere il tuo business.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="center">
            Solo con Connect@You potrai offrire ai tuoi clienti la possibilità
            di autenticarsi tramite Whatsapp, inserendo semplicemente il proprio
            numero di telefono.
          </p>
        </div>
      </div>
      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <h3>Scopri tutti i modi di accedere</h3>
        </div>
      </div>
      <div className="section" />
      <WaysToAccess />
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <h2>Come può aiutarti Connect@You?</h2>
          </div>
          <div className="column">
            <p className="description">
              Autenticarsi!?!
              <br />
              Basta un click...e sei subito in rete!              
              <br />
              <br />              
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p className="description">
              Persistenza
              <br />
              <br />
              È uno strumento per rendere più veloci le autenticazioni,
              soprattutto nei luoghi dove il cliente è abituale. L’utente dopo
              essersi autenticato una prima volta sulla tua rete non avrà
              bisogno di farlo in futuro, e potrà accedere con un solo click.
              Offri al tuo cliente una connessione WiFi “come a casa”!
              <br />
              <br />
              <br />
              Federazione
              <br />
              <br />È la possibilità di "federare" più reti tra loro. L’utente,
              già autenticatosi sulla “rete A”, arrivando nel locale dove dov’è
              presente la “rete B”, con un semplice click, sarà connesso
              automaticamente a questa.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="center">Ti potrebbe interessare...</p>
        </div>
      </div>
      <div className="section" />
      <CategoryButtons titlePage="Modalità di autenticazione" />
    </>
  );
};

export default Authentication;
